<template>
  <div v-if="isMountedRef" class="container pt-5">
    <div
      class="grid grid-cols-1 md:grid-cols-2 gap-4 justify-items-center mt-5"
    >
      <div class="text-center md:pt-[100px]">
        <h1 class="font-bold mb-5">{{ $t("error.503_title") }}</h1>
        <p>{{ $t("error.503_description") }}</p>
      </div>
      <div>
        <img
          src="/images/404.png"
          class="mb-5 max-w-[540px] w-auto"
          alt="404"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
const isMountedRef = ref(false);

onMounted(() => {
  isMountedRef.value = true;
});
</script>
