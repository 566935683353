export default defineNuxtPlugin(() => {
  const { data: authData, status: authStatus } = useAuth();

  const headersObject = {
    "X-Tenant": useRuntimeConfig().public.tenantId, // Set the headers directly here
  };
  if (authStatus.value === "authenticated" && authData.value?.token) {
    headersObject.authorization = `Bearer ${authData.value.token}`;
  }

  const api = $fetch.create({
    baseURL: useRuntimeConfig().public.apiBase,
    headers: headersObject,
    onRequest({ options }) {
      // Ensure headers are applied if not already present
      options.headers = options.headers || {};
      if (!options.headers["X-Tenant"]) {
        options.headers["X-Tenant"] = useRuntimeConfig().public.tenantId;
      }
      if (
        !options.headers.authorization &&
        authStatus.value === "authenticated" &&
        authData.value?.token
      ) {
        options.headers.authorization = `Bearer ${authData.value.token}`;
      }
    },
    onResponseError: sentryReportHelper,
  });

  // Expose to useNuxtApp().$api
  return {
    provide: {
      api,
    },
  };
});
