import { MeiliSearch } from "meilisearch";

export async function useMeilisearch(indexName: string, filter: any) {
  const config = useRuntimeConfig();
  const client = new MeiliSearch({
    host: config.public.meilisearchHost,
    apiKey: config.public.meilisearchKey,
  });
  const index = client.index(indexName);
  const query = await index.search("", filter);
  return query.hits;
}

export function useMeilisearchIndex(indexName: string) {
  const config = useRuntimeConfig();
  const client = new MeiliSearch({
    host: config.public.meilisearchHost,
    apiKey: config.public.meilisearchKey,
  });
  const index = client.index(indexName);
  return index;
}
