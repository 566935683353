import * as Sentry from "@sentry/vue";
import app from "~/package.json";

export default defineNuxtPlugin({
  name: "sentry-client-plugin",
  enforce: "pre",
  setup(nuxtApp) {
    const {
      public: { sentry },
    } = nuxtApp.$config;

    if (!sentry || !sentry.dsn) {
      return;
    }

    const config = {
      app: nuxtApp.vueApp,
      dsn: sentry.dsn,
      release: `${app.name}@${app.version}`,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
    };

    if (
      "environment" in sentry &&
      typeof sentry.environment === "string" &&
      sentry.environment !== ""
    ) {
      config.environment = sentry.environment;
    }

    if (
      "release" in sentry &&
      typeof sentry.release === "string" &&
      sentry.release !== ""
    ) {
      config.release = sentry.release;
    }

    if (
      "tracesSampleRate" in sentry &&
      typeof sentry.tracesSampleRate === "string" &&
      sentry.tracesSampleRate !== ""
    ) {
      config.tracesSampleRate = parseFloat(sentry.tracesSampleRate);
    }

    if (
      "replaysSessionSampleRate" in sentry &&
      typeof sentry.replaysSessionSampleRate === "string" &&
      sentry.replaysSessionSampleRate !== ""
    ) {
      config.replaysSessionSampleRate = parseFloat(
        sentry.replaysSessionSampleRate,
      );
    }

    if (
      "replaysOnErrorSampleRate" in sentry &&
      typeof sentry.replaysOnErrorSampleRate === "string" &&
      sentry.replaysOnErrorSampleRate !== ""
    ) {
      config.replaysOnErrorSampleRate = parseFloat(
        sentry.replaysOnErrorSampleRate,
      );
    }

    Sentry.init(config);
  },
});
