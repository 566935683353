import { default as _91_46_46_46slug_93ILszah2VNpMeta } from "/app/pages/[...slug].vue?macro=true";
import { default as _404STh7p7T7N0Meta } from "/app/pages/404.vue?macro=true";
import { default as arva98GZUkjmxsMeta } from "/app/pages/arva.vue?macro=true";
import { default as fizetesVM2YntSlaDMeta } from "/app/pages/fizetes.vue?macro=true";
import { default as _91_91slug_93_93MFDuk1LTXKMeta } from "/app/pages/manufacturers/[[slug]].vue?macro=true";
import { default as _91slug_93J6lJSDsk9nMeta } from "/app/pages/product/[sku]/[slug].vue?macro=true";
import { default as profileupBlgdy3vLMeta } from "/app/pages/profile.vue?macro=true";
import { default as rendelescrlC833xJ5Meta } from "/app/pages/rendeles.vue?macro=true";
import { default as searchfcnTL5fl5XMeta } from "/app/pages/search.vue?macro=true";
import { default as _91slug_93oBXjHdJAnQMeta } from "/app/pages/store/[slug].vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93ILszah2VNpMeta || {},
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: "404",
    path: "/404",
    component: () => import("/app/pages/404.vue")
  },
  {
    name: "arva",
    path: "/arva",
    component: () => import("/app/pages/arva.vue")
  },
  {
    name: "fizetes",
    path: "/fizetes",
    component: () => import("/app/pages/fizetes.vue")
  },
  {
    name: "manufacturers-slug",
    path: "/manufacturers/:slug?",
    meta: _91_91slug_93_93MFDuk1LTXKMeta || {},
    component: () => import("/app/pages/manufacturers/[[slug]].vue")
  },
  {
    name: "product-sku-slug",
    path: "/product/:sku()/:slug()",
    meta: _91slug_93J6lJSDsk9nMeta || {},
    component: () => import("/app/pages/product/[sku]/[slug].vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/app/pages/profile.vue")
  },
  {
    name: "rendeles",
    path: "/rendeles",
    component: () => import("/app/pages/rendeles.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/app/pages/search.vue")
  },
  {
    name: "store-slug",
    path: "/store/:slug()",
    component: () => import("/app/pages/store/[slug].vue")
  },
  {
    name: "termek-product-sku-slug",
    path: "/termek/:sku()/:slug()",
    meta: _91slug_93J6lJSDsk9nMeta || {},
    component: () => import("/app/pages/product/[sku]/[slug].vue")
  },
  {
    name: "fiok",
    path: "/fiok",
    component: () => import("/app/pages/profile.vue")
  },
  {
    name: "boltok-store-slug",
    path: "/boltok/:slug()",
    component: () => import("/app/pages/store/[slug].vue")
  },
  {
    name: "markak-manufacturers-slug",
    path: "/markak/:slug?",
    meta: _91_91slug_93_93MFDuk1LTXKMeta || {},
    component: () => import("/app/pages/manufacturers/[[slug]].vue")
  }
]