export default defineNuxtPlugin(() => {});

/*
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error instance, info) => {
    console.log("errorHandler", error);
  };

  nuxtApp.hook("vue:error", (error, instance, info) => {
    console.log("vue:error", error);
  });
});
*/
