<script setup lang="ts">
import type { NuxtError } from "#app";
import ErrorPage500 from "~/components/ErrorPage/ErrorPage500.vue";
import ErrorPage503 from "~/components/ErrorPage/ErrorPage503.vue";
import ErrorPage404 from "~/components/ErrorPage/ErrorPage404.vue";

const props = defineProps({
  error: {
    type: Object as () => NuxtError,
    default: () => ({}),
  },
});

const statusCodeMissing = computed(() => {
  if (!("statusCode" in props.error)) {
    return true;
  }

  if (props.error.statusCode < 100 || props.error.statusCode >= 600) {
    return true;
  }

  return false;
});

// -----------------------
// vue events
// -----------------------
/*
onMounted(() => {
  console.log(props.error);
});
*/
</script>

<template>
  <div>
    <!-- No status code -->
    <template v-if="statusCodeMissing">
      <!-- 500 -->
      <ErrorPage500 />
    </template>

    <!-- 404 -->
    <template v-if="error?.statusCode === 404">
      <NuxtLayout>
        <ErrorPage404 />
      </NuxtLayout>
    </template>

    <!-- 500 -->
    <template v-else-if="error?.statusCode === 500">
      <ErrorPage500 />
    </template>

    <!-- 503 -->
    <template v-else-if="error?.statusCode === 503">
      <ErrorPage503 />
    </template>

    <!-- otherwise -->
    <template v-else>
      <ErrorPage500 />
    </template>

</div>
</template>
