import * as Sentry from "@sentry/vue";
import type { Scope } from "@sentry/types";

export default function ({ request, response, options }) {
  Sentry.withScope((scope: Scope) => {
    const statusCode = response.status;

    if (statusCode === 401 || statusCode === 422) {
      return;
    }

    if (statusCode >= 500) {
      scope.setLevel("error");
    } else if (statusCode >= 400) {
      scope.setLevel("warning");
    } else {
      scope.setLevel("debug");
    }

    scope.setExtras({
      response,
      options: {
        method: options.method ?? "GET",
        headers: options.headers ?? [],
      },
    });

    Sentry.captureMessage(
      `Failed request: ${options.method ?? "GET"} ${request} - ${statusCode} ${response.statusText}`,
    );
  });
}
